import React from 'react';
import './Footer.css';
import { Link, NavLink } from 'react-router-dom'
import Navbar from './Navbar';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className='footer-contents'>
          {/*
            <ul>
            <li>
              <NavLink to='/' >
                Αρχική
              </NavLink>
              </li>
            <li>
              <NavLink to='/contact' >
                Επικοινωνία
              </NavLink>
            </li>
            <li><NavLink to='/' >
                Τοποθεσία
              </NavLink></li>
            </ul>
  */}
            <div className="social-media">
            <Link to='https://www.instagram.com/anavryta_field_hockey/' className='nav-links-social'>
                <i className="fa fa-instagram"></i>
            </Link>
            <Link to='https://www.facebook.com/AnavrytaHockeyClub' className='nav-links-social'>
                <i className="fa fa-facebook"></i>
            </Link>
            <Link to='https://www.youtube.com/c/anavrytahockeyclub' className='nav-links-social'>
                <i className="fa fa-youtube"></i>
            </Link>
            </div>
            <hr className='footer-divider'></hr>
            <a className='mail' href="mailto:info@anavrytahockey.gr">info@anavrytahockey.gr</a>
            <a className='tel' href="tel:2106531366">210 65 31 366</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
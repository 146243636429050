import React from 'react'
import '../Gallery.css'
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { motion } from "framer-motion";
import PageIndicator from '../../PageIndicator';

{/*
const images = [
    { src: './images/img-1.jpg', caption: '' },
    { src: './images/img-2.jpg', caption: '' },
    { src: './images/img-3.jpg', caption: '' },
    { src: './images/img-4.jpg', caption: '' },
    { src: './images/img-5.jpg', caption: '' },
  ];
*/}
  const LeftArrowIcon = () => (
    <svg viewBox="0 0 24 24" width="96" height="96">
      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
    </svg>
  );
  
  const RightArrowIcon = () => (
    <svg viewBox="0 0 24 24" width="96" height="96">
        <path
            d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"
            transform="translate(24,0) scale(-1,1)" />
    </svg>
  );
const GalleryEng = ({photos}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/contact")
        } else {
            navigate("/history")
        }
    };
    const handleGalleryTextBodyWheel = e => {
        e.stopPropagation();
    };
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(null);
    const handleNextImage = (event) => {
        event.stopPropagation();
        setSelectedImageIndex((index) => (index + 1) % photos.length);
    };

    const handlePrevImage = (event) => {
        event.stopPropagation();
        setSelectedImageIndex((index) => (index - 1 + photos.length) % photos.length);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          setSelectedImageIndex(null);
        }
      };
      const handleTouchStart = e => {
          setTouchStartX(e.touches[0].clientX);
      };
      const handleTouchMove = e => {
          if (touchStartX) {
              if (e.touches[0].clientX - touchStartX > 50) {
                  navigate("/history");
                  setTouchStartX(null);
              } else if (touchStartX - e.touches[0].clientX > 50) {
                  navigate("/contact");
                  setTouchStartX(null);
              }
          }
      };
      React.useEffect(() => {
        const handleKeyDown = (event) => {
            if (selectedImageIndex !== null){
                if (event.key === 'ArrowRight') {
                    handleNextImage(event);
                } else if (event.key === 'ArrowLeft') {
                    handlePrevImage(event);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImageIndex]);

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);
    return (
        <div className='gallery-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} >
            <motion.div className='gallery-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='gallery-text-divider-top'></hr>
                    <span className='gallery-text-title'>Gallery</span>
                    <div className='gallery-text-body' onWheelCapture={handleGalleryTextBodyWheel}>
                            {photos.length > 0 && (
                                <div className='image-grid'>
                                    {photos.sort((a,b) => b.id - a.id)
                                    .map((photo, index) => (
                                        <img 
                                            key={photo.id} 
                                            src={photo.attributes.photo.data.attributes.formats.medium.url}
                                            onClick={() => setSelectedImageIndex(index)}>
                                        </img>
                                    ))}
                                </div>
                            )}
                            {/*
                            {images.map((image, index) => (
                                <img
                                    key={image.src}
                                    src={image.src}
                                    alt={image.caption}
                                    onClick={() => setSelectedImageIndex(index)}
                                />
                            ))}
                            */}
                        {selectedImageIndex !== null && (
                            <div className="fullscreen"
                                onClick={() => setSelectedImageIndex(null)} >
                                    <div className='left-arrow' onClick={handlePrevImage}>
                                        <LeftArrowIcon/>
                                    </div>
                                    <img src={photos[selectedImageIndex].attributes.photo.data.attributes.url} alt={photos[selectedImageIndex].caption} onClick={(event) => event.stopPropagation()}/>
                                    <div className='right-arrow' onClick={handleNextImage} >
                                        <RightArrowIcon/>
                                    </div>
                            </div>
                        )}
                    </div>
                    <hr className='gallery-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={6} totalPages={7} />
        </div>
    );
  }
  
  export default GalleryEng;
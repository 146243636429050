import React, { useEffect } from 'react'
import '../News.css'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import PageIndicator from '../../PageIndicator';

const NewsEng = ({news}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null);
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/schedule")
        } else {
            navigate("/")
        }
    };
    const handleNewsTextBodyWheel = e => {
        e.stopPropagation();
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (e.touches[0].clientX - touchStartX > 50) {
                navigate("/");
                setTouchStartX(null);
            } else if (touchStartX - e.touches[0].clientX > 50) {
                navigate("/schedule");
                setTouchStartX(null);
            }
        }
    };

    return (
        <div className='news-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='news-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='news-text-divider-top'></hr>
                    <span className='news-text-title'>News</span>
                    <div className='news-text-body' onWheel={handleNewsTextBodyWheel}>
                        {news.length > 0 && (
                            <div>
                                {news.sort((a,b) => 
                                    new Date(b.attributes.date) - new Date(a.attributes.date)).map(newsItem => (
                                    <div key={newsItem.id}>
                                        <span className='date'>{newsItem.attributes.date.split("-").reverse().join("-")}</span>
                                        <br></br>
                                        <br></br>
                                        <span>{newsItem.attributes.texten}</span>
                                        <br></br>
                                        <br></br>
                                        <hr className='news-divider'></hr>
                                        <br></br>
                                    </div>
                            ))}
                            </div>
                        )}
                    </div>
                    <hr className='news-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={2} totalPages={7} />
        </div>
    );
  }
  
  export default NewsEng;
import { createSlice } from '@reduxjs/toolkit'

export const pageIdSlice = createSlice({
  name: 'pageId',
  initialState: {
    value: 0,
  },
  reducers: {
    setPageId: (state, newvalue) => {
      state = newvalue
    },
    getPageId: (state) => {
      return state
    }
  },
})

// Action creators are generated for each case reducer function
export const { setPageId, getPageId } = pageIdSlice.actions

export default pageIdSlice.reducer
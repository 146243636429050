import React, { useState, useRef, useEffect } from 'react'
import '../../Navbar.css'
import { Link, NavLink } from 'react-router-dom'
import LanguageSwitcherEng from './LanguageSwitcherEng'

const NavbarEng = ({onLanguageChange}) => {
  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)
  const navRef = useRef(null)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)
  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target)) {
      closeMobileMenu()
    }
  }
  window.addEventListener('resize', showButton)
  return (
    <>
        <nav className='navbar' ref={navRef} >
            <div className='navbar-container'>
                <Link to="/" className='navbar-logo'>
                  <img className='navbar-logo' src='logo.png' alt="Logo"></img>
                  <span className='navbar-name'>
                    Anavryta Hockey Club
                  </span>
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                  <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                  <li className="nav-item">
                    <NavLink to='/' className='nav-links' onClick={closeMobileMenu}>
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/news' className='nav-links' onClick={closeMobileMenu}>
                      News
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/schedule' className='nav-links' onClick={closeMobileMenu}>
                      Schedule
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/roster' className='nav-links' onClick={closeMobileMenu}>
                      Roster
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/history' className='nav-links' onClick={closeMobileMenu}>
                      History
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                      Gallery
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/contact' className='nav-links' onClick={closeMobileMenu}>
                      Contact
                    </NavLink>
                  </li>
                  <hr className="nav-divider" display="none"></hr>
                  <li className="nav-item-social">
                    <Link to='https://www.instagram.com/anavryta_field_hockey/' className='nav-links-social' onClick={closeMobileMenu}>
                      <i className="fa fa-instagram"></i>
                      <span className='nav-social-name'>Instagram</span>
                    </Link>
                  </li>
                  <li className="nav-item-social">
                    <Link to='https://www.facebook.com/AnavrytaHockeyClub' className='nav-links-social' onClick={closeMobileMenu}>
                      <i className="fa fa-facebook"></i>
                      <span className='nav-social-name'>Facebook</span>
                    </Link>
                  </li>
                  <li className="nav-item-social">
                    <Link to='https://www.youtube.com/c/anavrytahockeyclub' className='nav-links-social' onClick={closeMobileMenu}>
                      <i className="fa fa-youtube"></i>
                      <span className='nav-social-name'>Youtube</span>
                    </Link>
                  </li>
                  { /*
                    {button && <Button buttonStyle='btn--outline'>Επικοινωνία</Button>}
                  */}
                </ul>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px', marginRight: '50px'}}>
                  <LanguageSwitcherEng onLanguageChange={onLanguageChange}/>
                </div>
            </div>
        </nav>
    </>
  )
}

export default NavbarEng
import React from 'react'
import './Contact.css'
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { motion } from "framer-motion";
import PageIndicator from '../PageIndicator';

const Contact = ({contact}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
        } else {
            navigate("/gallery")
        }
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (e.touches[0].clientX - touchStartX > 50) {
                navigate("/gallery");
                setTouchStartX(null);
            }
        }
    };
    return (
        <div className='contact-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='contact-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='contact-text-divider-top'></hr>
                    <span className='contact-text-title'>Επικοινωνία</span>
                    <div className='contact-text-body'>
                        {contact.attributes && (
                            <span className='contact-info' key={contact.id}>
                                <p>{contact.attributes.grtoptext}</p>
                                <br></br>
                                {contact.attributes.gremailname}
                                <br></br>
                                <a href={`mailto:${contact.attributes.email}`}>{contact.attributes.email}</a>
                                <br></br>
                                <br></br>
                                {contact.attributes.grtelname}
                                <br></br>
                                <a href={`tel:${contact.attributes.telnospace}`}>{contact.attributes.tel}</a>
                                <br></br>
                                <br></br>
                                {contact.attributes.grtrainingdaysname}
                                <br></br>
                                <span className="contact-hours">
                                    {contact.attributes.grtrainingdays}
                                    <br></br>
                                    {contact.attributes.traininghours}
                                </span>
                            </span>
                        )}
                        {/*
                        <span className='contact-info'>
                            <p>Για οποιαδήποτε πληροφορία, μη διστάσετε να επικοινωνήσετε μαζί μας:</p>
                            <br></br>
                            Email
                            <br></br>
                            <a href="mailto:info@anavrytahockey.gr">info@anavrytahockey.gr</a>
                            <br></br>
                            <br></br>
                            Τηλέφωνο
                            <br></br>
                            <a href="tel:2106531366">210 65 31 366</a>
                            <br></br>
                            <br></br>
                            Ημέρες Προπονήσεων
                            <br></br>
                            <span className="contact-hours">
                                Τετάρτη/Κυριακή
                                <br></br>
                                17:30 - 19:30 
                            </span>
                        </span>
                        */}
                    </div>
                    <hr className='contact-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={7} totalPages={7} />
        </div>
    );
  }
  
  export default Contact;
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Home from './components/Pages/Home';
import News from './components/Pages/News';
import Schedule from './components/Pages/Schedule';
import Roster from './components/Pages/Roster';
import History from './components/Pages/History';
import Gallery from './components/Pages/Gallery';
import Contact from './components/Pages/Contact';
import NoMatch from './components/Pages/NoMatch'
import NavbarEng from './components/Pages/en/NavbarEng';
import HomeEng from './components/Pages/en/HomeEng';
import NewsEng from './components/Pages/en/NewsEng';
import ScheduleEng from './components/Pages/en/ScheduleEng';
import RosterEng from './components/Pages/en/RosterEng';
import HistoryEng from './components/Pages/en/HistoryEng';
import GalleryEng from './components/Pages/en/GalleryEng';
import ContactEng from './components/Pages/en/ContactEng';
import NoMatchEng from './components/Pages/en/NoMatchEng'
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function App() {
  const [language, setLanguage] = useState('Greek');
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage)
  };
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      document.title = "Αρχική · Anavryta HC";
    } else if (location.pathname === "/news") {
      document.title = "Νέα · Anavryta HC";
    } else if (location.pathname === "/schedule") {
      document.title = "Πρόγραμμα · Anavryta HC";
    } else if (location.pathname === "/roster") {
      document.title = "Ρόστερ · Anavryta HC";
    } else if (location.pathname === "/history") {
      document.title = "Ιστορία · Anavryta HC";
    } else if (location.pathname === "/gallery") {
      document.title = "Γκαλερί · Anavryta HC";
    } else if (location.pathname === "/contact") {
      document.title = "Επικοινωνία · Anavryta HC";
    }
  }, [location]);
  
  const [news, setNews] = useState([]);
  const newsUrl = "https://anavrytahockey.onrender.com/api/news";
  useEffect(() => {
    fetch(newsUrl ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setNews(data.data);
        })
        .catch((error) => {
            console.log(error.message);
        })
  }, []);

  const [schedules, setSchedules] = useState([]);
  const schedulesUrl = "https://anavrytahockey.onrender.com/api/schedules";
  useEffect(() => {
    fetch(schedulesUrl ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setSchedules(data.data.sort((a,b) => a.attributes.daynumber - b.attributes.daynumber));
        })
        .catch((error) => {
            console.log(error.message);
        })
  }, []);

  const [managers, setManagers] = useState([]);
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const mensUrl = "https://anavrytahockey.onrender.com/api/rosters-men";
  const womensUrl = "https://anavrytahockey.onrender.com/api/rosters-women";
  const managersUrl = "https://anavrytahockey.onrender.com/api/rosters-managers";

  useEffect(() => {
    const fetchRosters = async () => {
      const menResponse = await fetch(mensUrl);
      const menData = await menResponse.json();
      const sortedMenData = menData.data.sort((a, b) => a.attributes.playernumber - b.attributes.playernumber);
      setTeam1(sortedMenData.map(player => ({ name: player.attributes.playername, number: player.attributes.playernumber })));

      const womenResponse = await fetch(womensUrl);
      const womenData = await womenResponse.json();
      const sortedWomenData = womenData.data.sort((a, b) => a.attributes.playernumber - b.attributes.playernumber);
      setTeam2(sortedWomenData.map(player => ({ name: player.attributes.playername, number: player.attributes.playernumber })));

      const managersResponse = await fetch(managersUrl);
      const managersData = await managersResponse.json();
      const sortedManagersData = managersData.data.sort((a, b) => a.attributes.managernumber - b.attributes.managernumber);
      setManagers(sortedManagersData.map(manager => manager.attributes.managername));
    };

    fetchRosters();
  }, []);

  const [history, setHistory] = useState([]);
  const [council, setCouncil] = useState([]);
  const historyUrl = "https://anavrytahockey.onrender.com/api/histories";
  const councilUrl = "https://anavrytahockey.onrender.com/api/councils";

  useEffect(() => {
    const fetchHistory = async () => {
      const historyResponse = await fetch(historyUrl);
      const historyData = await historyResponse.json();
      const highestIdHistory = historyData.data.reduce((prev, current) => (prev.id > current.id) ? prev : current);
      setHistory(highestIdHistory);

      const councilResponse = await fetch(councilUrl);
      const councilData = await councilResponse.json();
      const sortedCouncilData = councilData.data.sort((a, b) => a.attributes.priority - b.attributes.priority);
      setCouncil(sortedCouncilData);
    };

    fetchHistory();
  }, []);

  const [photos, setPhotos] = useState([]);
  const photosUrl = "https://anavrytahockey.onrender.com/api/photos?populate=*";
  useEffect(() => {
    fetch(photosUrl ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            setPhotos(data.data);
        })
        .catch((error) => {
            console.log(error.message);
        })
  }, []);
  const [contact, setContact] = useState([]);
  const contactUrl = "https://anavrytahockey.onrender.com/api/contacts";
  useEffect(() => {
    fetch(contactUrl ,{
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      })
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            const highestIdContact = data.data.reduce((prev, current) => (prev.id > current.id) ? prev : current);
            setContact(highestIdContact);
        })
        .catch((error) => {
            console.log(error.message);
        })
  }, []);
  return (
    <>
      {language === 'Greek' ? (
        <>
        <Navbar onLanguageChange={handleLanguageChange} />
        <Hero />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/news' element={<News news={news} />} />
          <Route path='/schedule' element={<Schedule schedules={schedules} />} />
          <Route path='/roster' element={<Roster managers={managers} team1={team1} team2={team2}/>} />
          <Route path='/history' element={<History history={history} council={council} />} />
          <Route path='/gallery' element={<Gallery photos={photos} />} />
          <Route path='/contact' element={<Contact contact={contact} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
        </>
      ) : (
        <>
        <NavbarEng onLanguageChange={handleLanguageChange} />
        <Hero />
        <Routes>
          <Route path='/' element={<HomeEng />} />
          <Route path='/news' element={<NewsEng news={news} />} />
          <Route path='/schedule' element={<ScheduleEng schedules={schedules} />} />
          <Route path='/roster' element={<RosterEng managers={managers} team1={team1} team2={team2}/>} />
          <Route path='/history' element={<HistoryEng history={history} council={council}/>} />
          <Route path='/gallery' element={<GalleryEng photos={photos} />} />
          <Route path='/contact' element={<ContactEng contact={contact} />} />
          <Route path="*" element={<NoMatchEng />} />
        </Routes>
        <Footer />
        </>
      )}
    </>
  );
}

export default App;

import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <div className="hero" onContextMenu={e => e.preventDefault()} style={{ width: "100%"}} >
        <video className="hero-video" disablePictureInPicture width="100%" height="auto" controls={false} autoPlay muted loop controlsList="nodownload">
          <source src="./videos/test-video-4.m4v" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className='content-container'>
      </div>
    </div>
  );
};

export default Hero;
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './LanguageSwitcher.css';

const LanguageSwitcher = ({onLanguageChange}) => {
  const [language, setLanguage] = useState('Greek');
  const handleButtonClick = () => {
    const newLanguage = language === 'Greek' ? 'English' : 'Greek';
    setLanguage(newLanguage);
    onLanguageChange(newLanguage);
  };

  return (
    <div className='language-switcher'>
      <div className="language-switcher-button">
          <div className='languages'>
            <NavLink className='language-container' style={{backgroundColor: '#3e8e41'}}>
              <img src="/language_gr.svg" alt="Greek language flag"/>
              <span>Ελληνικά</span>
            </NavLink>
            <NavLink className='language-container' onClick={handleButtonClick}>
              <img src="/language_gb.svg" alt="English language flag" />
              <span>English</span>
            </NavLink>
          </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
import React from 'react';

const PageIndicator = ({ currentPage, totalPages }) => {
  const circles = [];

  for (let i = 1; i <= totalPages; i++) {
    circles.push(
      <svg height="12" width="12" key={i}>
        <circle cx="6" cy="6" r="4" fill={i === currentPage ? 'white' : 'none'} stroke="white" strokeWidth="1" />
      </svg>
    );
  }

  return <div style={{ display: 'flex', justifyContent: 'center'}}>{circles}</div>;
};

export default PageIndicator;
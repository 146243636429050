import React from 'react'
import '../Schedule.css'
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { AnimatePresence, motion } from "framer-motion"
import PageIndicator from '../../PageIndicator'

const ScheduleEng = ({schedules}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/roster")
        } else {
            navigate("/news")
        }
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (e.touches[0].clientX - touchStartX > 50) {
                navigate("/news");
                setTouchStartX(null);
            } else if (touchStartX - e.touches[0].clientX > 50) {
                navigate("/roster");
                setTouchStartX(null);
            }
        }
    };
    return (
        <div className='schedule-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='schedule-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='schedule-text-divider-top'></hr>
                    <span className='schedule-text-title'>Schedule</span>
                    <div className='schedule-text-body'>
                        <div className="training-schedule">
                            {schedules.map(schedule => (
                                <div key={schedule.id} className="schedule-day-container">
                                    <div className="schedule-day">{schedule.attributes.dayen}</div>
                                    <div className="schedule-hours">{schedule.attributes.hours}</div>
                                </div>
                            ))}
                            </div>
                        </div>
                    <hr className='schedule-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={3} totalPages={7} />
        </div>
    );
  }
  
  export default ScheduleEng;
import React from 'react'
import '../Roster.css'
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { motion } from "framer-motion";
import PageIndicator from '../../PageIndicator';

const RosterEng = ({managers, team1, team2}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/history")
        } else {
            navigate("/schedule")
        }
    };
    const handleRosterTextBodyWheel = e => {
        e.stopPropagation();
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (e.touches[0].clientX - touchStartX > 50) {
                navigate("/schedule");
                setTouchStartX(null);
            } else if (touchStartX - e.touches[0].clientX > 50) {
                navigate("/history");
                setTouchStartX(null);
            }
        }
    };
    return (
        <div className='roster-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='roster-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='roster-text-divider-top'></hr>
                    <span className='roster-text-title'>Roster</span>
                    <div className='roster-text-body' onWheelCapture={handleRosterTextBodyWheel}>
                        <div className="roster">
                            
                            <div className="managers">
                                <div className="team">
                                    <div className='manager-team-title'>Managers</div>
                                    {managers.map(manager => (
                                    <span key={manager} className="manager">
                                        {manager}
                                        <br></br>
                                    </span>
                                    ))}
                                </div>
                            </div>
                            <div className="teams">
                                <div className="team">
                                <div className='team-title'>
                                    Men's Team
                                </div>
                                {team1.map(player => (
                                    <div key={player.name} className="player">
                                    <span className="player-number">{player.number}</span>
                                    <span className="player-name">{player.name}</span>
                                    </div>
                                ))}
                                </div>
                                <div className="team">
                                <div className='team-title'>
                                    Women's Team
                                </div>
                                {team2.map(player => (
                                    <div key={player.name} className="player">
                                    <span className="player-number">{player.number}</span>
                                    <span className="player-name">{player.name}</span>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        {/*
                        <table className='table-managers'>
                            <tbody>
                            <tr>
                                <th colSpan={3}>
                                    Υπεύθυνοι Αγωνιστικού Τμήματος
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Ανδρέας Μιχαήλ
                                </td>
                                <td>
                                    Κατερίνα Μουρίκη
                                </td>
                                <td>
                                    Σοφία Παπαγεωργίου
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table>
                            <tbody>
                            <tr>
                                <th></th>
                                <th>Ανδρική Ομάδα</th>
                                <th>Γυναικεία Ομάδα</th>
                            </tr>
                            <tr>
                                <th className='roster-number'>1</th>
                                <td>Χάρης Χατζηαγγέλου (Τερματοφύλακας)</td>
                                <td>Έλενα Αρναούτη</td>
                            </tr>
                            <tr>
                                <th>2</th>
                                <td>Θεόδωρος Μαυριάς</td>
                                <td>Νάστια Αρναούτη</td>
                            </tr>
                            <tr>
                                <th>3</th>
                                <td>Ανδρέας Μιχαήλ</td>
                                <td>Ελένη Διαμαντή</td>
                            </tr>
                            <tr>
                                <th>4</th>
                                <td>Κώστας Λέλης</td>
                                <td>Σοφία Παπαγεωργίου</td>
                            </tr>
                            <tr>
                                <th>5</th>
                                <td>Κωνσταντίνος Μπαϊρακτάρης</td>
                                <td>Ματίνα Αγγελοπούλου</td>
                            </tr>
                            <tr>
                                <th>6</th>
                                <td>Στέλιος Λιάσκος</td>
                                <td>Έλλη Λίτση</td>
                            </tr>
                            <tr>
                                <th>7</th>
                                <td>Ρωμανός Τζουνάκος</td>
                                <td>Φαίη Μπρούπη</td>
                            </tr>
                            <tr>
                                <th>8</th>
                                <td>Κωνσταντίνος Τσιάμης</td>
                            </tr>
                            <tr>
                                <th>9</th>
                                <td>Γιώργος Μητρόπουλος</td>
                            </tr>
                            <tr>
                                <th>10</th>
                                <td>Γιώργος Ελμενσάουι</td>
                            </tr>
                            <tr>
                                <th>11</th>
                                <td>Παναγιώτης Δρακούλης</td>
                            </tr>
                            <tr>
                                <th>12</th>
                                <td>Bαγγέλης Μαντζανάς</td>
                            </tr>
                            <tr>
                                <th>13</th>
                                <td>Oriol Peña</td>
                            </tr>
                            <tr>
                                <th>14</th>
                                <td>Edward Torre</td>
                            </tr>
                            <tr>
                                <th>15</th>
                                <td>Waqas Younas</td>
                            </tr>
                            <tr>
                                <th>16</th>
                                <td>Ali Kazmi</td>
                            </tr>
                            <tr>
                                <th>17</th>
                                <td>Διαμιανός Τζουνάκος</td>
                            </tr>
                            <tr>
                                <th>18</th>
                                <td>Yiannis Kunze</td>
                            </tr>
                            <tr>
                                <th>19</th>
                                <td>Θανάσης Νικητόπουλος</td>
                            </tr>
                            <tr>
                                <th>20</th>
                                <td>Φίλιππος Γεωργαντάς</td>
                            </tr>
                            </tbody>
                        </table>
    */}
                    </div>
                    <hr className='roster-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={4} totalPages={7} />
        </div>
    );
  }
  
  export default RosterEng;
import React from 'react'
import './NoMatch.css'
import { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import { motion } from "framer-motion";
import PageIndicator from '../PageIndicator';

const NoMatch = () => {
    return (
        <div className='nomatch-container' style={{height: 'calc(100vh - 200px)'}}>
            <motion.div className='nomatch-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='nomatch-text-divider-top'></hr>
                    <span className='nomatch-text-title'>404</span>
                    <div className='nomatch-text-body'>
                        <span className='nomatch-info'>
                            <p>Η σελίδα που ζητήσατε δε βρέθηκε</p>
                            <br></br>
                            <Link to='/'>
                                <p Link='/'>Επιστροφή στην αρχική</p>
                            </Link>
                        </span>
                    </div>
                    <hr className='nomatch-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={7} totalPages={7} />
        </div>
    );
  }
  
  export default NoMatch;
import React from 'react'
import '../History.css'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion";
import PageIndicator from '../../PageIndicator';

const HistoryEng = ({history, council}) => {
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/gallery")
        } else {
            navigate("/roster")
        }
    };
    const handleGalleryTextBodyWheel = e => {
        e.stopPropagation();
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (e.touches[0].clientX - touchStartX > 50) {
                navigate("/roster");
                setTouchStartX(null);
            } else if (touchStartX - e.touches[0].clientX > 50) {
                navigate("/gallery");
                setTouchStartX(null);
            }
        }
    };
    return (
        <div className='history-container' style={{height: 'calc(100vh - 200px)'}} onWheel={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='history-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='history-text-divider-top'></hr>
                    <span className='history-text-title'>History</span>
                    <div className='history-text-body' onWheelCapture={handleGalleryTextBodyWheel}>
                        <div className='history-paragraph'>
                            <div className='history-paragraph-title'>Brief History</div>
                            {history.attributes && (
                                <div key={history.id} className='history-paragraph-text'>
                                    {history.attributes.historyen}
                                </div>
                            )}
                        </div>
                        <div className='board'>
                            <div className='board-title'>Board of Directors</div>
                            {council.length > 0 && (
                                council.map(member => (
                                    <div key={member.id} className='board-members'>
                                        <div className='council-member-title'>
                                            {member.attributes.titleen}
                                        </div>
                                        <div className='council-member-name'>
                                            {member.attributes.name}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                        {/*

                        Αθλητικός όμιλος χόκεϋ επί χόρτου από το 1949, ο οποίος ξεκίνησε από το ομώνυμο σχολείο, το Ελληνικό Εκπαιδευτήριο Αναβρύτων, στο Μαρούσι Αττικής. Ο όμιλος έχει συμμετοχές σε πανελλήνια πρωταθλήματα, καθώς και σε 3 Eυρωπαϊκά Πρωταθλήματα Συλλόγων Σάλας (Eurohockey Indoor Club Challenges) της Ευρωπαϊκής Ομοσπονδίας Χόκεϋ Επί Χόρτου (European Hockey Federation –  EHF), το 2015 στη Βουλγαρία, το 2016 στη Σλοβενία και το 2017 στην Τουρκία, όπου και κατέκτησε την 4η θέση.

                        The Anavryta Field Hockey Club was established in 1949 in Maroussi Attica, originating from the eponymous school. The club has a rich history of participating in national championships and has also represented Greece in three European Indoor Club Hockey Championships (Eurohockey Indoor Club Challenges) organized by the European Hockey Federation (EHF). In 2015, the club competed in Bulgaria, followed by Slovenia in 2016 and Turkey in 2017, where it achieved an impressive 4th place finish.

                        
                        <span className="history-contents">
                        Αθλητικός όμιλος χόκεϋ επί χόρτου από το 1949, ο οποίος ξεκίνησε από το ομώνυμο σχολείο, το Ελληνικό Εκπαιδευτήριο Αναβρύτων, στο Μαρούσι Αττικής.
                        </span>
                        <span className="history-contents">
                        <br></br>
                        Ο όμιλος έχει συμμετοχές σε πανελλήνια πρωταθλήματα, καθώς και σε 3 Eυρωπαϊκά Πρωταθλήματα Συλλόγων Σάλας (Eurohockey Indoor Club Challenges) της Ευρωπαϊκής Ομοσπονδίας Χόκεϋ Επί Χόρτου (European Hockey Federation –  EHF), το 2015 στη Βουλγαρία, το 2016 στη Σλοβενία και το 2017 στην Τουρκία, όπου και κατέκτησε την 4η θέση.
                        <br></br>
                        </span>
                        <span>
                            <br>
                            </br>
                        </span>
                        <table className="ds-table">
                            <tbody>
                                <tr>
                                <th colSpan="2" style={{verticalAlign: "middle"}}>
                                Σύνθεση Διοικητικού Συμβουλίου
                                </th>
                                </tr>
                            <tr>
                                <th>
                                    Πρόεδρος
                                </th>
                                <td>
                                    Γιώργος Δραμυτινός
                                </td>
                                </tr>
                                <tr>
                                    <th>
                                    Αντιπρόεδρος
                                    </th>
                                    <td>
                                    Γεράσιμος Βεντούρης
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Γενικός Γραμματέας
                                    </th>
                                    <td>
                                    Δημήτρης Ζαφειρίου
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Ταμίας
                                    </th>
                                    <td>
                                    Χρήστος Κασιμάτης
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                    Μέλος
                                    </th>
                                    <td>
                                    Νίκος Πουλάκος
                                    </td>
                                </tr>
                                </tbody>
                        </table>
    */}
                    </div>
                    <hr className='history-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator currentPage={5} totalPages={7} />
        </div>
    );
  }
  
  export default HistoryEng;
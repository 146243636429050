import React from 'react'
import './Home.css'
import { useState } from 'react';
import { useNavigate} from 'react-router-dom'
import { motion, useScroll } from "framer-motion";
import PageIndicator from '../PageIndicator';

const Home = () => {
    const { scrollYProgress } = useScroll();
    const navigate = useNavigate();
    const [touchStartX, setTouchStartX] = useState(null)
    const handleOnWheel = e => {
        if(e.deltaY > 0) {
            navigate("/news")
        }
    };
    const handleTouchStart = e => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = e => {
        if (touchStartX) {
            if (touchStartX - e.touches[0].clientX > 50) {
                navigate("/news");
                setTouchStartX(null);
            }
        }
    };
    return (
        <div className='home-container' style={{height: 'calc(100vh - 200px)'}} onWheelCapture={handleOnWheel} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <motion.div className='home-text'
                style={{userSelect: "none"}} 
                initial={{ opacity: 0 }}
                animate={{ opacity: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }}
                transition={{ delay: 0.1 }}
                exit={{ opacity: "0" }}>
                    <hr className='home-text-divider-top'></hr>
                    <img className='home-text-logo' src='logo.png'></img>
                    <span className='home-text-title'>Anavryta Hockey Club</span>
                    <span className='home-text-body'>Αθλητικός Σύλλογος Χόκεϋ Επί Χόρτου από το 1949</span>
                    <hr className='home-text-divider-bottom'></hr>
            </motion.div>
            <PageIndicator className='home-page-indicator' currentPage={1} totalPages={7} />
        </div>
    );
  }
  
  export default Home;